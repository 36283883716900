import { graphql } from 'gatsby';
import React, { FunctionComponent } from 'react';
import { Seo } from 'components/seo/seo';
import { CmsContext } from 'logic/context/cms';
import { App } from '../app/app';

const BecomeTutorPage: FunctionComponent<any> = ({ data: { contentfulBecomeTutorPage } }) => {
  return (
    <CmsContext.Provider value={contentfulBecomeTutorPage}>
      <Seo
        title={contentfulBecomeTutorPage.pageMeta.metatitle}
        description={contentfulBecomeTutorPage.pageMeta.metadescription}
      />
      <App />
    </CmsContext.Provider>
  );
};

export default BecomeTutorPage;

export const query = graphql`
  query BecomeTutorPage($language: String) {
    contentfulBecomeTutorPage(node_locale: { eq: $language }) {
      pageMeta {
        metatitle
        metadescription
      }
      headingAboveSearch
      headingAboveSearchSecondLine
      numberFirst
      numberSecond
      numberLast
      faq {
        ... on ContentfulFaq {
          quoteAuthor
          content {
            content
          }
        }
      }
    }
  }
`;
